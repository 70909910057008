import makeStyles from '@material-ui/core/styles/makeStyles';

import footer_bg_d from '../../assets/img/footer/footer-d-min.jpeg';
import footer_bg_m from '../../assets/img/footer/footer-m-min.jpeg';
import { media } from '../../hooks/useDevice';

export default makeStyles(() => ({
	footer: {
		background: `url(${footer_bg_d}) no-repeat`,
		backgroundPosition: 'top center',
		backgroundSize: '100% 100%',
		height: 170,

		[media.mobile]: {
			background: `url(${footer_bg_m}) no-repeat`,
			backgroundPosition: 'top center',
			backgroundSize: '100%',
			marginTop: '1rem',
			height: '45vw',
		},
	},

	footer_container: {
		padding: '35px 0 0',
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		justifyContent: 'space-between',
		alignItems: 'flex-end',

		[media.mobile]: {
			gridTemplateColumns: '1fr',
			justifyContent: 'center',
			textAlign: 'center',
			padding: '1.66667rem 0 0',
		},
	},

	footer_logo: {
		[media.mobile]: {
			margin: 'auto',
			width: '44.8vw',
		},
	},

	footer_left: {},

	footer_social: {
		marginBottom: 12,
		display: 'flex',
		justifyContent: 'center',

		[media.mobile]: {
			margin: '2.3rem auto .8rem',
		},
	},

	footer_ic: {
		marginLeft: 17,

		[media.mobile]: {
			width: '1.5rem',
			height: '1.5rem',
			marginLeft: '1rem',
		},
	},

	footer_copyright: {
		textAlign: 'right',
		fontSize: 10,

		[media.mobile]: {
			textAlign: 'center',
			fontSize: '0.833333rem',
		},
	},
}));
