
const english = {
	translations: {
		'1 Whitelabel': '1 Whitelabel',
		'24 Hours Service Support': '24 Hours Service Support',
		'About Us': 'About Us',
		'Best Lottery System': 'Best Lottery System',
		Casino: 'CASINO',
		'Choose a Template': 'Choose a Template',
		Code: 'English',
		'Company / Group Name': 'Company / Group Name',
		'Contact Name': 'Contact Name',
		'Contact Us': 'Contact Us',
		'Database Security': 'Database Security',
		'Deposit Fee': 'Deposit Fee*',
		Deposit_Fee_desc: 'Deposit Fees Can Be Claimed Again If The Total Player Winloss Whitelabel Client Has Reached 200,000,000 IDR Within 6 Months.',
		'Domain Website': 'Domain Website',
		Email: 'Email',
		'Enter your message': 'Enter your message ...',
		'Field is required': 'Field is required',
		Fishing: 'Fishing Game',
		'Free Setup Fee': 'Free Setup Fee',
		'Google SEO Friendly': 'Google SEO Friendly',
		'Have Whitelabel before': 'Have you had a Vigor Whitelabel before?',
		Home: 'Home',
		'How can we help': 'How can we help',
		IDR: 'IDR',
		'Information Details': 'Information Details',
		'Integrated Bonus System': 'Integrated Bonus System',
		'Invalid email': 'Invalid email',
		'Live Report Response': 'Live Report Response',
		Name: 'Name',
		No: 'No',
		'Our Benefits': 'Our Benefits',
		'Our Pools': 'Our Pools',
		'Our Products': 'Our Products',
		'Our Themes': 'Our Themes',
		Our_Pools_Desc: 'They are all trusted official lottery that provide a variety of lottery bet, attractive gift and discount which draws every day.',
		'Please select your template': 'Please select your template',
		'Reference From': 'Reference From',
		Register: 'Register',
		'Seamless System': 'Seamless System',
		Service: 'Service',
		Services: 'Services',
		'Skype ID': 'Skype ID',
		Slots: 'SLOT',
		Sports: 'Sports',
		Subject: 'Subject',
		Submit: 'Submit',
		Term_Agree: 'I have read and agree to the terms and conditions provided.',
		Togel: 'TOGEL',
		'User Friendly': 'User Friendly',
		WhatsApp: 'WhatsApp',
		Yes: 'Yes',
		aboutUs_1_title: 'Development Method',
		aboutUs_1_txt: 'VIGOR has been designed with simpler, more modern features and better performance, which will make it easier to connect and develop in line with market demands and players\' desires. VIGOR also certainly delivers an impressive performance you\'ve never seen before.',
		aboutUs_2_title: 'How we\'re different',
		aboutUs_2_txt: 'VIGOR provides something new and different. Indeed, we provide something fresh, including several aspects ranging from the ease of operation to security that we have collaborated with several trusted partners in many projects.',
		aboutUs_3_title: 'Our promise',
		aboutUs_3_txt: 'VIGOR offers services to make the best thing possible by providing the best and most professional service. We also have a well-experienced and talented team in their fields. Of course, with reasonable confidence, we will provide you with the most exciting and safe web experience.',
		aboutUs_4_title: 'Our solutions',
		aboutUs_4_txt: 'With VIGOR\'s best service, attractive appearances, and security, we are happy to deliver a more excellent website experience with the latest technology and updates for you and your players.',
		custom_theme_desc: 'Bring your ideas to life with more customizable templates and new creative options when you register our Whitelabel.',
		'err:content_memo_valid': 'Please input your message',
		'err:invalid_company_name': 'Invalid Company name',
		'err:invalid_contact_name': 'Please input contact name',
		'err:invalid_domain_website': 'Invalid domain website',
		'err:invalid_email': 'Invalid email',
		'err:invalid_skype_id': 'Invalid Skype ID',
		'err:invalid_template_key': 'Invalid template key',
		'err:invalid_username': 'Please input your name',
		'err:invalid_whatsapp': 'Invalid WhatsApp',
		'err:subject_memo_valid': 'Please input the subject',
		form_submit_success: 'Your form has been submitted',
		home_desc: 'Here we do not leave your success to chance, we guarantee it. So, are you ready to go all in?',
		home_title: 'GET READY TO EXPERIENCE THE ULTIMATE ONLINE GAMING EXPERIENCE!',
		required_accept_term: 'You must accept the terms',
		service_desc: 'VIGOR will bring your dream brand to life with various facilities and convenience through our best white label services. Providing multiple choices of excellent designs, many different templates with an optimal back-end system, and easy-to-use, VIGOR will give your brand a unique identify and you are ready to go.',
		service_price: '30',
		service_unit: 'JT',
		submit_success_message: 'Thank you for taking the time to leave us a message. Rest assured that our team will attend to your concern as soon as possible.',

	}
};
export default english;
