import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
	donut: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'inline-block',
		border: '4px solid rgba(0, 0, 0, 0.1)',
		borderLeftColor: '#7983ff',
		borderRadius: '50%',
		width: 30,
		height: 30,
		animation: '$donut-spin 1.2s linear infinite',
	},

	'@keyframes donut-spin': {
		'0%': {
			transform: 'rotate(0deg)'
		},
		'100%': {
			transform: 'rotate(360deg)'
		},
	},

	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center',
		perspective: 1024,
		transition: 'opacity .2s ease-in-out,visibility .2s ease-in-out',
		display: 'block',
		zIndex: 3001,
		background: 'rgba(0,0,0,0.5)',
	}
}));
