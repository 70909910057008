import makeStyles from '@material-ui/core/styles/makeStyles';
import { media } from '../../hooks/useDevice';

export const useStyles = makeStyles(() => ({
	selectLanguage__container: {
		position: 'relative',
		userSelect: 'none',
	},

	selectLanguage__dropDown: {
		position: 'relative',
		padding: '0 .7rem 0 .3rem',
		background: '#000000',
		color: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: '2rem',
		borderRadius: '10px',
		border: '1px solid #6A2FEC',
		zIndex: 11,
		cursor: 'pointer',
		transition: 'all 0.2s ease-out',

		[media.mobile]: {
			height: '2.5rem',
		}
	},

	selectLanguage__dropDown_active: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},

	selectLanguage__dropDown__left: {
		display: 'flex',
		alignItems: 'center',
	},

	selectLanguage__globeIcon: {
		width: 23,
		height: 23,
		border: '1px solid #FFFFFF',
		borderRadius: '50%',
		marginRight: '.5rem',
		objectFit: 'cover',
	},

	selectLanguage__dropDownIcon: {
		width: '10px',
		transform: 'scaleY(100%)',
		transition: 'all 350ms',
	},

	selectLanguage__dropDownIcon_open: {
		transform: 'scaleY(-100%)',
	},

	selectLanguage__language: {
		textTransform: 'uppercase',
		lineHeight: '1em',
	},

	selectLanguage__dropdown__list: {
		zIndex: 1000,
		background: '#000000',
		color: '#FFFFFF',
		padding: 0,
		position: 'absolute',
		top: 30,
		listStyle: 'none',
		width: '100%',
		borderRadius: '0 0 16px 16px',
		borderWidth: '0 1px 1px 1px',
		borderStyle: 'solid',
		borderColor: '#6A2FEC',
		overflow: 'hidden',
		margin: 0,
		animation: '$dropdownOpen 200ms ease-in',
		transformOrigin: 'top',
		opacity: 1,
		transform: 'scaleY(100%)',
		maxHeight: '100vh',
		transition: 'all 0.2s ease-out',
	},

	selectLanguage__dropdown__list_hide: {
		opacity: 0,
		maxHeight: 0,
		transform: 'scaleY(0%)',
		transition: 'all 0.2s ease-in',
	},

	selectLanguage__dropdown__item: {
		padding: '.5rem 4px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',

		'&:hover': {
			background: '#6A2FEC',
		},
	},
}));
