
const english = {
	translations: {
		'1 Whitelabel': '1 Whitelabel',
		'24 Hours Service Support': '24 JAM Costumber Service',
		'About Us': 'Tentang Kami',
		'Best Lottery System': 'Sistem Lottery Terbaik',
		Casino: 'CASINO',
		'Choose a Template': 'Pilih Template',
		Code: 'Indonesian',
		'Company / Group Name': 'Nama Brand',
		'Contact Name': 'Nama Kontak',
		'Contact Us': 'Hubungi kami',
		'Database Security': 'Keamanan Database',
		'Deposit Fee': 'Biaya Deposit*',
		Deposit_Fee_desc: 'Biaya Deposit Bisa Diklaim Kembali Apabila Total Player Winloss Whitelabel Client Sudah Mencapai 200,000,000 IDR Dalam Kurun Waktu 6 Bulan.',
		'Domain Website': 'Alamat Domain',
		Email: 'Email',
		'Enter your message': 'Masukkan Pesan ...',
		'Field is required': 'Wajib di isi',
		Fishing: 'Tembak Ikan',
		'Free Setup Fee': 'Layanan Setup Gratis',
		'Google SEO Friendly': 'Google SEO Friendly',
		'Have Whitelabel before': 'Apakah sudah memiliki Vigor Whitelabel sebelumnya?',
		Home: 'Home',
		'How can we help': 'Ada Yang Bisa Kami Bantu?',
		IDR: 'IDR',
		'Information Details': 'Detail Informasi',
		'Integrated Bonus System': 'Sistem Bonus Terintergrasi',
		'Invalid email': 'Email tidak valid',
		'Live Report Response': 'Respon Laporan Langsung',
		Name: 'Nama',
		No: 'Tidak',
		'Our Benefits': 'Benefit Kami',
		'Our Pools': 'Pasaran',
		'Our Products': 'Produk Kami',
		'Our Themes': 'Warna Template',
		Our_Pools_Desc: 'They are all trusted official lottery that provide a variety of lottery bet, attractive gift and discount which draws every day.',
		'Please select your template': 'Please select your template',
		'Reference From': 'Upline / Rekomendasi',
		Register: 'Register',
		'Seamless System': 'Seamless System',
		Service: 'Service',
		Services: 'Service',
		'Skype ID': 'ID Skype',
		Slots: 'SLOT',
		Sports: 'Sports',
		Subject: 'Subject',
		Submit: 'Submit',
		Term_Agree: 'Saya telah membaca dan setuju dengan syarat dan ketentuan yang diberikan.',
		Togel: 'TOGEL',
		'User Friendly': 'Mudah Digunakan',
		WhatsApp: 'WhatsApp',
		Yes: 'Ya',
		aboutUs_1_title: 'Metode Pengembangan',
		aboutUs_1_txt: 'VIGOR sudah dirancang dengan fitur yang lebih simple dan lebih modern, dengan performa yang lebih baik, tentunya akan memberikan kemudahan untuk terkoneksi dan berkembang bersamaan dengan keinginan pasar dan keinginan pemain. VIGOR juga dipastikan dapat memberikan performa yang tidak pernah Anda lihat sebelumnya.',
		aboutUs_2_title: 'Bagaimana Kita Berbeda',
		aboutUs_2_txt: 'VIGOR memberikan sesuatu yang baru dan juga berbeda, Kami memberikan sesuatu yang lebih segar tentunya mencakup beberapa aspek mulai dari kemudahan mengoperasikan dan juga keamanan yang kita jalin bersamaan dengan beberapa partner terpercaya dalam',
		aboutUs_3_title: 'Janji Kita',
		aboutUs_3_txt: 'Dengan penyediaan layanan yang terbaik dan juga paling professional, VIGOR memberikan layanan untuk membuat sesuatu yang paling baik. Kami juga memiliki talenta terbaik dan paling berbakat di bidangnya. Tentunya dengan kepercayaan diri yang baik, Kami akan memberikan Anda pengalaman memiliki web paling menarik dan juga aman.',
		aboutUs_4_title: 'Solusi Kita',
		aboutUs_4_txt: 'Dengan menyediakan pelayanan yang terbaik dan tampilan yang menarik serta keamanan yang VIGOR sediakan, Kami dengan senang hati akan memberikan Anda pengalaman memiliki website yang lebih baik dengan teknologi yang termodern dengan update yang terbaik untuk Anda dan pemain Anda.',
		custom_theme_desc: 'Bring your ideas to life with more customizable templates and new creative options when you register our Whitelabel.',
		'err:content_memo_valid': 'Masukkan Pesan',
		'err:invalid_company_name': 'Nama company tidak valid',
		'err:invalid_contact_name': 'Nama Kontak tidak valid',
		'err:invalid_domain_website': 'Alamat Domain tidak valid',
		'err:invalid_email': 'Email tidak valid',
		'err:invalid_skype_id': 'ID Skype tidak valid',
		'err:invalid_template_key': 'Template tidak valid',
		'err:invalid_username': 'Masukkan Nama',
		'err:invalid_whatsapp': 'WhatsApp tidak valid',
		'err:subject_memo_valid': 'Masukkan Subject',
		form_submit_success: 'Your form has been submitted',
		home_desc: 'Here we do not leave your success to chance, we guarantee it. So, are you ready to go all in?',
		home_title: 'GET READY TO EXPERIENCE THE ULTIMATE ONLINE GAMING EXPERIENCE!',
		required_accept_term: 'You must accept the terms',
		service_desc: 'VIGOR will bring your dream brand to life with various facilities and convenience through our best white label services. Providing multiple choices of excellent designs, many different templates with an optimal back-end system, and easy-to-use, VIGOR will give your brand a unique identify and you are ready to go.',
		service_price: '30',
		service_unit: 'JT',
		submit_success_message: 'Thank you for taking the time to leave us a message. Rest assured that our team will attend to your concern as soon as possible.',

	}
};
export default english;
