import React, { useState } from 'react';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';

import VigorLogo from '../../assets/img/vigor-min.png';
import MenuImg from '../../assets/img/Square Menu.svg';
import dropdown from '../../assets/img/dropdown.svg';
import useStyles from './styles';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import Translate from '../Translate';

const HeaderMobile = () => {
	const classes = useStyles();
	const [menuOpen, setMenuOpen] = useState(false);
	const [openProducts, setOpenProducts] = useState(false);

	return (<div className={classes.header}>
		<Link to={'/'} className={classes.headerMobile__logo}>
			<img className={classes.headerMobile__logo__img} src={VigorLogo} alt='' />
		</Link>
		<img className={classes.headerMobile__menu}
			onClick={() => setMenuOpen(value => !value)}
			src={MenuImg} alt='' />

		<div
			 className={clsx(
				 classes.headerMobile__dropdownMenu,
				 { [classes.headerMobile__dropdownMenu__hide]: !menuOpen }
			 )}
		>

			<div
				className={clsx(
					classes.headerMobile__dropdownMenu__backdrop,
					{ [classes.headerMobile__dropdownMenu__backdrop__hide]: !menuOpen }
				)}
				onClick={() => setMenuOpen(() => false)}
			/>
			<div className={clsx(classes.headerMobile__dropdownMenu__wrapper,
				{ [classes.headerMobile__dropdownMenu__wrapper_hide]: !menuOpen }
			)}>
				<SelectLanguage className={classes.headerMobile__dropdownMenu__lang} />

				<NavLink to={'/'}
					onClick={() => setMenuOpen(() => false)}
					className={classes.headerMobile__products__item}
				>
					<Translate i18nKey='Home' />
				</NavLink>

				<div className={classes.headerMobile__products}>
					<div
						className={classes.headerMobile__control}
						onClick={() => setOpenProducts(value => !value)}
					>
						<Translate i18nKey='Our Products' />
						<img src={dropdown} alt={''} className={clsx(
							classes.headerMobile__products__dropdownIcon,
							{
								[classes.headerMobile__products__dropdownIcon_open]: openProducts
							}
						)} />
					</div>

					<div className={clsx(
						classes.headerMobile__products__list, {
							[classes.headerMobile__products__list_hide]: !openProducts
						}
					)}>
						<NavLink to={'/sports'}
							onClick={() => setMenuOpen(() => false)}
							className={classes.headerMobile__products__item}
						>
							<Translate i18nKey='Sports' />
						</NavLink>
						<NavLink to={'/slot'}
							onClick={() => setMenuOpen(() => false)}
							className={classes.headerMobile__products__item}
						>
							<Translate i18nKey='Slots' />
						</NavLink>
						<NavLink to={'/casino'}
							onClick={() => setMenuOpen(() => false)}
							className={classes.headerMobile__products__item}
						>
							<Translate i18nKey='Casino' />
						</NavLink>
						<NavLink to={'/togel'}
							onClick={() => setMenuOpen(() => false)}
							className={classes.headerMobile__products__item}
						>
							<Translate i18nKey='Togel' />
						</NavLink>
						<NavLink to={'/tembak-ikan'}
							onClick={() => setMenuOpen(() => false)}
							className={classes.headerMobile__products__item}
						>
							<Translate i18nKey='Fishing' />
						</NavLink>
					</div>
				</div>

				<NavLink to={'/service'}
					onClick={() => setMenuOpen(() => false)}
					className={classes.headerMobile__products__item}
				>
					<Translate i18nKey='Service' />
				</NavLink>

				<NavLink to={'/about-us'}
					onClick={() => setMenuOpen(() => false)}
					className={classes.headerMobile__products__item}
				>
					<Translate i18nKey='About Us' />
				</NavLink>
			</div>
		</div>
	</div>);
};

export default HeaderMobile;
