import React, { useCallback, useReducer, useState } from 'react';
import clsx from 'clsx';
import map from 'lodash/map';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';

import contactUs__img from '../../assets/img/contact-us-min.jpeg';
import validateEmail from '../../func/validateEmail';
import sendRequest from '../../api/sendRequest';
import Translate from '../Translate';

import useStyles from './styles';

const initFields = {
	username: '',
	email: '',
	subject: '',
	content: '',
};

const ContactUs = () => {
	const classes = useStyles();
	const [noti, setNoti] = useState('');
	const [formError, setFormError] = useState('');
	const { t } = useTranslation();

	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		initFields
	);

	const [errors, setErrors] = useState(initFields);

	const validate = useCallback((name) => {
		const value = userInput[name];
		let error = '';

		switch (name) {
			case 'username':
			case 'email':
			case 'subject':
			case 'content':
				if (!userInput[name].replace(/\s/g, '')) {
					error = 'Field is required';
				}
				break;
			default:
				break;
		}

		if (!error) {
			switch (name) {
				case 'email':
					if (!validateEmail(value)) {
						error = 'Invalid email';
					}
					break;
				default:
					break;
			}
		}

		setErrors(err => ({
			...err,
			[name]: error,
		}));

		return !error;
	}, [userInput]);

	const onSubmit = useCallback(event => {
		event.preventDefault();
		setNoti('');
		setFormError('');

		let invalidFlag = false;

		map(userInput, (input, key) => {
			const isValid = validate(key);

			if (!isValid) {
				invalidFlag = true;
			}
		});

		if (invalidFlag) {
			return;
		}

		sendRequest('/global/compose/contact_us', userInput).then((res) => {
			if (res?.data?.success) {
				setNoti('form_submit_success');
				setUserInput(initFields);
				setErrors(() => initFields);
			} else {
				if (res?.data?.data) {
					setErrors(err => ({
						...err,
						...res?.data?.data
					}));
				} else {
					setFormError(() => res?.data?.err);
				}
			}
		});
	}, [validate, userInput]);

	const onInputChange = useCallback(event => {
		let { name, value } = event.target;

		setUserInput({
			[name]: value,
		});
	}, []);

	return (<div className={clsx(classes.contactUs, 'container')}>
		<div className={classes.contactUs__title}>
			<Translate i18nKey='Contact Us' />
		</div>

		<div className={classes.contactUs__content}>

			<form
				className={classes.contactUs__form}
				noValidate
				onSubmit={onSubmit}
			>
				<AnimationOnScroll animateIn="animate__zoomInUp" animateOnce>
					<label>
						<div className={classes.contactUs__label}>
							<Translate i18nKey='Name' />
							<span className={classes.contactUs__required}>*</span>
						</div>

						<input
							className={classes.contactUs__input}
							name='username'
							value={userInput.username}
							onChange={onInputChange}
							maxLength={100}
						/>

						<div className={classes.contactUs__input_error}>
							{errors.username}
						</div>
					</label>

					<label>
						<div className={classes.contactUs__label}>
							<Translate i18nKey='Email' />
							<span className={classes.contactUs__required}>*</span>
						</div>

						<input
							className={classes.contactUs__input}
							name='email'
							value={userInput.email}
							onChange={onInputChange}
						/>

						<div className={classes.contactUs__input_error}>
							{errors.email}
						</div>
					</label>

					<label>
						<div className={classes.contactUs__label}>
							<Translate i18nKey='Subject' />
							<span className={classes.contactUs__required}>*</span>
						</div>

						<input
							className={classes.contactUs__input}
							name='subject'
							value={userInput.subject}
							onChange={onInputChange}
						/>

						<div className={classes.contactUs__input_error}>
							{errors.subject}
						</div>
					</label>

					<label>
						<div className={classes.contactUs__label}>
							<Translate i18nKey='How can we help' />
							<span className={classes.contactUs__required}>*</span>
						</div>

						<textarea
							className={clsx(classes.contactUs__input, classes.contactUs__textarea)}
							placeholder={t('Enter your message')}
							name='content'
							value={userInput.content}
							onChange={onInputChange}
						/>

						<div className={classes.contactUs__input_error}>
							{errors.content}
						</div>
					</label>

					<div className={classes.contactUs__form__success}>
						<Translate i18nKey={noti} />
					</div>

					<div className={classes.contactUs__form__error}>
						<Translate i18nKey={formError} />
					</div>

					<button type='submit' className={classes.contactUs__submit}>
						<Translate i18nKey='Submit' />
					</button>
				</AnimationOnScroll>
			</form>

			<div className={classes.contactUs__imgWrapper}>
				<img className={classes.contactUs__img} src={contactUs__img} alt='' />
			</div>
		</div>
	</div>);
};

export default ContactUs;
