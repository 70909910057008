import React, { useMemo, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import VigorLogo from '../../assets/img/vigor-min.png';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import Translate from '../Translate';

import useStyles from './styles';

const Header = () => {
	const classes = useStyles();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleMouseEnter = useCallback(() => {
		setOpen(() => true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setOpen(() => false);
	}, []);

	const handleClose = useCallback((event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(() => false);
	}, [anchorRef]);

	const renderProducts = useMemo(() => {
		if (!open) {
			return '';
		}

		return (<Popper
			className={classes.header__popper}
			open={open}
			anchorEl={anchorRef.current}
			transition
			disablePortal
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						animation: 'none',
					}}
				>
					<Paper>
						<ClickAwayListener onClickAway={handleClose}>
							<div>
								<NavLink to={'/sports'} className={classes.header__popper__item}>
									<Translate i18nKey='Sports' className={classes.header__popper__txt} />
								</NavLink>
								<NavLink to={'/slot'} className={classes.header__popper__item}>
									<Translate i18nKey='Slots' className={classes.header__popper__txt} />
								</NavLink>
								<NavLink to={'/casino'} className={classes.header__popper__item}>
									<Translate i18nKey='Casino' className={classes.header__popper__txt} />
								</NavLink>
								<NavLink to={'/togel'} className={classes.header__popper__item}>
									<Translate i18nKey='Togel' className={classes.header__popper__txt} />
								</NavLink>
								<NavLink to={'/tembak-ikan'} className={classes.header__popper__item}>
									<Translate i18nKey='Fishing' className={classes.header__popper__txt} />
								</NavLink>
							</div>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>);
	}, [open, classes, handleClose]);

	return (<div className={classes.header}>
		<div className={clsx('container', classes.header_container)}>
			<NavLink to={'/'} activeClassName='is-active'>
				<img className={classes.header_logo} src={VigorLogo} alt='' />
			</NavLink>

			<div className={classes.header_left}>
				<div className={classes.header_buttons}>
					<NavLink to={'/register'} className={classes.header_registerBtn}>
						<Translate i18nKey='Register' />
					</NavLink>
					<SelectLanguage className={classes.header_lang} />
				</div>

				<div className={classes.header_menu}>
					<NavLink to={'/'} className={classes.header_menu__item}>
						<Translate i18nKey='Home' />
					</NavLink>
					<div
						ref={anchorRef}
						className={classes.header_menu__item}
						onClick={handleMouseEnter}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<div className={classes.header_menu__product}>
							<Translate i18nKey='Our Products'/>
						</div>
						{renderProducts}
					</div>
					<NavLink to={'/service'} className={classes.header_menu__item}>
						<Translate i18nKey='Service'/>
					</NavLink>
					<NavLink to={'/about-us'} className={classes.header_menu__item}>
						<Translate i18nKey='About Us'/>
					</NavLink>
				</div>
			</div>
		</div>
	</div>);
};

export default Header;
