import React from 'react';
import { Trans, withTranslation } from 'react-i18next';

const Translate = ({ i18nKey, values, className = '', style = {}, ...props }) => {
	return (<span className={className} style={style}>
		<Trans i18nKey={i18nKey} values={values} {...props} />
	</span>);
};

export default withTranslation()(Translate);
