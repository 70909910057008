import React from 'react';
import clsx from 'clsx';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Translate from '../../../components/Translate';
import { useDevice } from '../../../hooks/useDevice';

import useStyles from './styles';

const Benefit = () => {
	const classes = useStyles();
	const device = useDevice();

	return (<div className={classes.benefit}>
		<div className={clsx(classes.benefit_container, 'container')}>
			<div className={classes.benefit_title}>
				<AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
					<Translate i18nKey='Our Benefits' />
				</AnimationOnScroll>
			</div>

			<div className={classes.benefit_list}>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInLeft' : 'animate__fadeInBottomRight'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/1-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Seamless System' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInDown' : 'animate__fadeInUp'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/2-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Integrated Bonus System' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInDown' : 'animate__fadeInBottomLeft'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/3-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Live Report Response' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInRight' : 'animate__fadeInRight'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/4-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Database Security' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInLeft' : 'animate__zoomIn'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/5-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='User Friendly' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInUp' : 'animate__fadeInLeft'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/6-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Google SEO Friendly' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInUp' : 'animate__fadeInTopRight'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/7-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='Best Lottery System' />
						</div>
					</div>
				</AnimationOnScroll>

				<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInRight' : 'animate__fadeInTopLeft'}>
					<div className={classes.benefit_item}>
						<div className={classes.benefit_item_imgWrapper}>
							<img className={classes.benefit_item_img}
								src={require('../../../assets/img/home/benefit/8-min.png')} alt={''}
							/>
						</div>
						<div className={classes.benefit_item_txt}>
							<Translate i18nKey='24 Hours Service Support' />
						</div>
					</div>
				</AnimationOnScroll>

			</div>
		</div>
	</div>);
};

export default Benefit;
