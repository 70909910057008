import makeStyles from '@material-ui/core/styles/makeStyles';

import popularTitle_bg from '../../../assets/img/home/popular-title-bg-min.png';
import { media } from '../../../hooks/useDevice';

export default makeStyles(() => ({
	popular_title: {
		position: 'relative',
		background: `url(${popularTitle_bg}) no-repeat`,
		backgroundPosition: 'center',
		backgroundSize: '100% 100%',
		textAlign: 'center',
		padding: '2rem 0',

		[media.mobile]: {
			padding: '1rem 0 .75rem',
		},
	},

	popular_title_1: {
		color: '#B366FF',
		fontSize: '1.375rem',
		fontFamily: 'Novecento Wide Bold',
		letterSpacing: '0.1em',
		marginBottom: '1rem',

		[media.mobile]: {
			fontSize: '1.166667rem',
			marginBottom: '.5rem',
		},
	},

	popular_title_2: {
		color: '#FFFFFF',
		fontSize: '1.5rem',
		fontFamily: 'Novecento Wide Medium',

		[media.mobile]: {
			fontSize: '1rem'
		},
	},

	popular_content: {
		position: 'relative',
		margin: '1rem auto 0',

		[media.mobile]: {
			padding: 0,
			margin: '1rem 0',
		},
	},

	popular_img: {
		width: '100%',

		[media.mobile]: {
			padding: '0 1rem',
		},
	},
}));
