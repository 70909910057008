import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import map from 'lodash/map';
import get from 'lodash/get';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import useStyles from './styles';
import ApiComponent from '../../../api/ApiComponent';
import Translate from '../../../components/Translate';

const Popular = () => {
	const classes = useStyles();
	const [templates, setTemplates] = useState([]);

	const sliderSettings = useMemo(() => ({
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		swipeToSlide: true,
		swipe: true,
		lazyLoad: true,
	}), []);

	return (<>
		<ApiComponent path={'/global/landing_page/template_list'} setData={setTemplates} />

		<div className={classes.popular_title}>
			<div className={'container'}>
				<AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
					<div className={classes.popular_title_1}>
						<Translate i18nKey='home_title' />
					</div>
				</AnimationOnScroll>
				<AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
					<div className={classes.popular_title_2}>
						<Translate i18nKey='home_desc' />
					</div>
				</AnimationOnScroll>
			</div>
		</div>
		<div className={clsx(classes.popular_content, 'container')}>
			<Slider {...sliderSettings}>
				{
					map(templates, (item) => (
						<img
							className={classes.popular_img}
							src={get(item, 'carousel[0]')}
							alt={''}
							key={item.template_key}
						/>
					))
				}
			</Slider>
		</div>
	</>);
};

export default Popular;
