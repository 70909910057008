import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import i18n from 'locales/i18n';
import Home from './page/Home/Home';
import Header from './components/Header/Header';
import HeaderMobile from './components/HeaderMobile/HeaderMobile';
import { useDevice } from './hooks/useDevice';
import Loading from './components/Loading/Loading';
import './App.css';

const Register = React.lazy(() => import('./page/Register/Register'));
const Sports = React.lazy(() => import('./page/Sports/Sports'));
const Slot = React.lazy(() => import('./page/Slot/Slot'));
const Casino = React.lazy(() => import('./page/Casino/Casino'));
const Fishing = React.lazy(() => import('./page/Fishing/Fishing'));
const Togel = React.lazy(() => import('./page/Togel/Togel'));
const AboutUs = React.lazy(() => import('./page/AboutUs/AboutUs'));
const Service = React.lazy(() => import('./page/Service/Service'));

function App() {
	const device = useDevice();

	return (
		<I18nextProvider i18n={i18n}>
			<RecoilRoot>
				<Suspense fallback={<Loading />}>
					<div className="App">
						<div id='wl-modal-root' />

						{device.desktop ? <Header/> : <HeaderMobile/>}
						<Routes>
							<Route path="/" element={<Home/>}/>
							<Route path="/register" element={<Register/>}/>
							<Route path="/sports" element={<Sports/>}/>
							<Route path="/slot" element={<Slot/>}/>
							<Route path="/casino" element={<Casino/>}/>
							<Route path="/tembak-ikan" element={<Fishing/>}/>
							<Route path="/togel" element={<Togel/>}/>
							<Route path="/about-us" element={<AboutUs/>}/>
							<Route path="/service" element={<Service/>}/>
						</Routes>
					</div>
				</Suspense>
			</RecoilRoot>
		</I18nextProvider>
	);
}

export default App;
