import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import english from './en/locale.en';
import indonesian from './id/locale.id';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		load: 'languageOnly',
		resources: {
			en: english,
			id: indonesian,
		},
		fallbackLng: 'id',
		supportedLngs: ['id', 'en'],
		debug: false,
		lng: localStorage.i18nextLng || 'id',
		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ',',
		},

		react: {
			wait: true,
		},
	});

export default i18n;
