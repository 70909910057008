export const parseUrl = (url = '', params = '') => {
	if (!!url) {
		if (/^data:/.test(url)) {
			// Base64 image
			return url;
		} else if (/^[0-9+][0-9]+$/.test(url)) {
			// Phone number
			return 'tel:' + url;
		} else {
			const [newLink, queryParams] = url.split('?');
			let newParams = '?' + params;

			if (queryParams) {
				newParams = '?' + queryParams + '&' + params;
			}

			if (!/^http(|s):\/\//.test(url) && !/^\//.test(url)) {
				// Link
				return '//' + newLink + newParams;
			} else {
				return newLink + newParams;
			}
		}
	}
	return '';
};

export default parseUrl;
