import { useLayoutEffect, useState } from 'react';

export const defineMedia = {
	desktop: '(min-width: 768.8px)',
	mobile: '(max-width: 768.8px)',
};

export const media = {
	desktop: `@media ${defineMedia.desktop}`,
	mobile: `@media ${defineMedia.mobile}`,
};

const matchMedia = () => ({
	desktop: window.matchMedia(defineMedia.desktop).matches,
	mobile: window.matchMedia(defineMedia.mobile).matches,
});

export const useDevice = () => {
	const [device, setDevice] = useState(matchMedia());

	const updateSize = () => {
		setDevice(matchMedia());
	};

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, []);
	return device;
};
