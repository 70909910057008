import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';
import map from 'lodash/map';
import 'slick-carousel/slick/slick.css';

import useStyles from './styles';

const Banner = ({ images = [] }) => {
	const classes = useStyles();
	const [loaded, setLoaded] = useState(false);

	const settings = useMemo(() => ({
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		swipeToSlide: true,
		swipe: true,
		lazyLoad: true,
	}), []);

	return (<div style={{
		height: !loaded ? '100vh' : '',
	}}>
		<Slider {...settings}>
			{map(images, image => (
				<div className={classes.home_bannerWrapper} key={image}>
					<img className={classes.home_bannerImg} src={image} alt='' onLoad={() => setLoaded(true)} />
				</div>
			))}
		</Slider>
	</div>);
};

export default Banner;
