import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

const sendRequest = async (
	path,
	data,
	timeout = 60000,
) => {
	if (!path) {
		return;
	}

	const request = {
		url: path,
		method: 'POST',
		data: data,
		timeout,
		responseType: 'json',
	};

	return new Promise((resolve) => {
		axios
			.request(request)
			.then((res) => {
				if (res.status >= 200 && res.status < 300) {
					resolve(res);
				} else {
					resolve({ isError: true, ...res.data });
				}
			})
			.catch(() => {
				resolve({});
			});
	});
};

export default sendRequest;
