import React from 'react';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import VigorLogo from '../../assets/img/vigor-min.png';
import YoutubeLogo from '../../assets/img/footer/youtube-min.png';
import FacebookLogo from '../../assets/img/footer/fb-min.png';
import InstagramLogo from '../../assets/img/footer/insta-min.png';
import TwitterLogo from '../../assets/img/footer/tw-min.png';
import socialState from '../../recoil/social';
import parseUrl from '../../func/parseUrl';

import useStyles from './styles';

const Footer = () => {
	const classes = useStyles();
	const social = useRecoilValue(socialState);

	return (<div className={classes.footer}>
		<div className={clsx('container', classes.footer_container)}>
			<AnimationOnScroll animateIn="animate__bounceInLeft" offset={10} animateOnce>
				<img className={classes.footer_logo} src={VigorLogo} alt='' />
			</AnimationOnScroll>
			<div className={classes.footer_left}>
				<div className={classes.footer_social}>

					<AnimationOnScroll animateIn="animate__bounceInDown" offset={10} delay={0} animateOnce>
						{social?.youtube && <a href={parseUrl(social?.youtube)} target='_blank' rel="noopener noreferrer">
							<img className={classes.footer_ic} src={YoutubeLogo} alt={''}/>
						</a>}
					</AnimationOnScroll>

					<AnimationOnScroll animateIn="animate__bounceInDown" offset={10} delay={50} animateOnce>
						{social?.facebook && <a href={parseUrl(social?.facebook)} target='_blank' rel="noopener noreferrer">
							<img className={classes.footer_ic} src={FacebookLogo} alt={''}/>
						</a>}
					</AnimationOnScroll>

					<AnimationOnScroll animateIn="animate__bounceInDown" offset={10} delay={100} animateOnce>
						{social?.instagram && <a href={parseUrl(social?.instagram)} target='_blank' rel="noopener noreferrer">
							<img className={classes.footer_ic} src={InstagramLogo} alt={''}/>
						</a>}
					</AnimationOnScroll>

					<AnimationOnScroll animateIn="animate__bounceInDown" offset={10} delay={200} animateOnce>
						{social?.twitter && <a href={parseUrl(social?.twitter)} target='_blank' rel="noopener noreferrer">
							<img className={classes.footer_ic} src={TwitterLogo} alt={''}/>
						</a>}
					</AnimationOnScroll>

				</div>
				<AnimationOnScroll animateIn="animate__bounceInRight" offset={10} delay={500} animateOnce>
					<div className={classes.footer_copyright}>© 2019 VIGOR ALL RIGHTS RESERVED</div>
				</AnimationOnScroll>
			</div>
		</div>
	</div>);
};

export default Footer;
