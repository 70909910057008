import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
	home_bannerWrapper: {
		display: 'grid',
	},

	home_bannerImg: {
		display: 'grid',
		width: '100vw',
	},
}));
