import React from 'react';
import toString from 'lodash/toString';

const ParseHtml = ({ children }) => {
	return (<div
		className={'jodit-wysiwyg'}
		dangerouslySetInnerHTML={{ __html: toString(children) }}
	/>);
};

export default ParseHtml;
