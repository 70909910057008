import makeStyles from '@material-ui/core/styles/makeStyles';
import { media } from '../../../hooks/useDevice';

export default makeStyles(() => ({
	benefit: {
		position: 'relative',
	},

	benefit_container: {
		position: 'relative',
		zIndex: 2,
	},

	benefit_title: {
		fontFamily: 'Novecento Wide Bold',
		color: '#FF3D00',
		fontSize: '1.875rem',
		marginBottom: '1.75rem',

		[media.mobile]: {
			fontSize: '1.33333rem'
		}
	},

	benefit_list: {
		display: 'grid',
		gridTemplateColumns: 'auto auto auto auto',
		justifyContent: 'space-between',
		gridGap: '3.25rem',

		[media.mobile]: {
			display: 'flex',
			gridTemplateColumns: 'unset',
			justifyContent: 'center',
			gridGap: '1rem 3rem',
			flexWrap: 'wrap',
		}
	},

	benefit_item: {
		display: 'grid',
		fontFamily: 'Novecento Wide Medium',
		gridGap: 16,
		textAlign: 'center',
		justifyContent: 'center',

		[media.mobile]: {
			width: '24vw',

			'&:nth-child(2)': {
				height: '13rem',
				alignItems: 'flex-start',
				gridTemplateRows: 'auto 1fr',
			},
			'&:nth-child(1), &:nth-child(3)': {
				alignItems: 'flex-end',
				gridTemplateRows: '1fr auto',
			},
		},
	},

	benefit_item_imgWrapper: {
		[media.mobile]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},

	benefit_item_img: {
		display: 'grid',
		margin: 'auto',

		[media.mobile]: {
			width: '24vw',
		},
	},

	benefit_item_txt: {
		fontSize: '1.125rem',
		textTransform: 'uppercase',
		maxWidth: '11em',

		[media.mobile]: {
			fontSize: '1rem',
			width: '30vw',
		},
	},
}));
