import makeStyles from '@material-ui/core/styles/makeStyles';
import { media } from '../../hooks/useDevice';

export default makeStyles(() => ({
	contactUs: {},

	contactUs__title: {
		textTransform: 'uppercase',
		fontFamily: 'Novecento Wide Bold',
		fontSize: '1.875rem',
		lineHeight: '2.25rem',
		letterSpacing: '0.2em',
		color: '#FF3D00',
		marginBottom: '1.25rem',

		[media.mobile]: {
			fontSize: '1.5rem',
			marginBottom: '1rem',
		},
	},

	contactUs__content: {
		display: 'grid',
		gridTemplateColumns: '44.7789275634995% 1fr',

		[media.mobile]: {
			gridTemplateColumns: '1fr',
		},
	},

	contactUs__form: {
		position: 'relative',
		background: '#3D1A69',
		color: '#FFFFFF',
		padding: '3.5rem 1.625rem 1.5625rem',
		borderTop: '.25rem solid #672BC0',

		[media.mobile]: {
			padding: '4.25rem 1rem 1rem',
			fontSize: '1.166667rem',
		},

		'&:before': {
			content: '" "',
			top: 0,
			left: '50%',
			position: 'absolute',
			background: '#672BC0',
			display: 'block',
			height: '.6rem',
			width: '10rem',
			transform: 'translateX(-50%) matrix3d(' +
				'1, 0, 0, 0, ' +
				'0, 1, 0, 0.01, ' +
				'0, 0, 1, 0, ' +
				'0, 0, 0, 1' +
				')',
		},
	},

	contactUs__label: {
		fontFamily: "'Open Sans', sans-serif",
		fontWeight: 400,
		lineHeight: '1.375rem',
		margin: '0 0 .5rem',
	},

	contactUs__input: {
		fontSize: 'inherit',
		fontFamily: "'Open Sans', sans-serif",
		background: '#100420',
		color: '#FFFFFF',
		borderRadius: '0.375rem',
		border: 'none',
		width: '100%',
		height: '2.175rem',
		padding: '1rem',
		margin: 0,
		display: 'block',

		[media.mobile]: {
			height: '3rem',
			borderRadius: '.5rem',
		},
	},

	contactUs__required: {
		color: '#E90C0C',
		marginLeft: '.3em',
	},

	contactUs__input_error: {
		color: '#E90C0C',
		fontSize: '.7rem',
		padding: '.2rem 0 .6rem',
	},

	contactUs__textarea: {
		height: '8.14375rem',
		resize: 'none',
	},

	contactUs__submit: {
		border: 'none',
		width: '9rem',
		height: '2.1625rem',
		background: 'linear-gradient(82.78deg, #6E2FF2 4.86%, #232686 101.82%)',
		color: '#FFFFFF',
		borderRadius: '0.625rem',
		margin: '.4rem auto 0',
		display: 'block',
		fontSize: '1rem',
		fontFamily: "'Quicksand', sans-serif",
		textTransform: 'uppercase',

		[media.mobile]: {
			fontSize: '1.333333rem',
			width: '100%',
			background: '#0779E3',
			borderRadius: '.5rem',
			height: '3rem',
		},
	},

	contactUs__imgWrapper: {
		[media.mobile]: {
			display: 'none',
		},
	},

	contactUs__img: {
		display: 'grid',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},

	contactUs__form__success: {
		textAlign: 'center',
		color: '#089052',
	},

	contactUs__form__error: {
		textAlign: 'center',
		color: '#E90C0C',
	},
}));
