import React from 'react';

import { useStyles } from './styles';

const Loading = () => {
	const classes = useStyles();

	return (
		<div className={classes.overlay}>
			<div className={classes.donut} />
		</div>
	);
};

export default Loading;
