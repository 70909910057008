import makeStyles from '@material-ui/core/styles/makeStyles';
import { media } from '../../../hooks/useDevice';

export default makeStyles(() => ({
	whatWeThink: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		gridGap: '3.25rem',
		margin: '0 auto 1rem',

		[media.mobile]: {
			gridTemplateColumns: '1fr',
			gridGap: '1rem',
		},
	},

	whatWeThink_img: {
		width: '100%',
		display: 'grid',
	},

	whatWeThink_text: {},

	whatWeThink_title: {
		color: '#FF3D00',
		fontFamily: 'Novecento Wide Bold',
		fontStyle: 'normal',
		// fontWeight: 700,
		fontSize: '1.875rem',
		textAlign: 'right',
		letterSpacing: '0.2em',
	},

	whatWeThink_content: {
		textAlign: 'justify',
		fontSize: '1rem',
		lineHeight: '1.375rem',
		fontFamily: "'Open Sans', sans-serif",
		letterSpacing: '0.1em',
	},
}));
