import React from 'react';
import clsx from 'clsx';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useDevice } from '../../../hooks/useDevice';

import ParseHtml from '../../../components/ParseHtml';
import whatWeThing_img from '../../../assets/img/home/WhatWeThink-min.png';

import useStyles from './styles';

const WhatWeThink = ({ data }) => {
	const classes = useStyles();
	const device = useDevice();

	return (<div className={clsx(classes.whatWeThink, 'container')}>
		<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInTopLeft' : 'animate__slideInUp'}>
			<div className={classes.whatWeThink_img}>
				<img src={whatWeThing_img} alt='' />
			</div>
		</AnimationOnScroll>

		<AnimationOnScroll animateOnce animateIn={device.desktop ? 'animate__fadeInTopRight' : 'animate__slideInRight'}>
			<div className={classes.whatWeThink_text}>
				<div className={classes.whatWeThink_title}>
					{data?.content?.title}
				</div>
				<div className={classes.whatWeThink_content}>
					<ParseHtml>
						{data?.content?.content}
					</ParseHtml>
				</div>
			</div>
		</AnimationOnScroll>
	</div>);
};

export default WhatWeThink;
