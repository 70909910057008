import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
	header: {
		background: '#000000CC',
		height: '17vw',
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1000,
		fontFamily: "'Quicksand', sans-serif",
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '100vw',
	},

	headerMobile__logo: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	headerMobile__logo__img: {
		height: 'calc(100% - 1rem)',
		width: 'auto',
	},

	headerMobile__menu: {
		position: 'absolute',
		width: '2rem',
		height: '2rem',
		left: '1rem',
		top: '50%',
		transform: 'translateY(-50%)',
	},

	headerMobile__dropdownMenu: {
		position: 'fixed',
		height: 'calc(100% - 17vw)',
		top: '17vw',
		width: '100%',
		zIndex: 999,
	},

	headerMobile__dropdownMenu__hide: {
		height: 0,
	},

	headerMobile__dropdownMenu__backdrop: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		background: '#000000CC',
	},

	headerMobile__dropdownMenu__backdrop__hide: {
		display: 'none',
	},

	headerMobile__dropdownMenu__lang: {
		width: '57vw',
	},

	headerMobile__dropdownMenu__wrapper: {
		position: 'relative',
		background: '#3D1A69',
		color: '#FFFFFF',
		padding: '5rem 0 2.5rem',
		display: 'grid',
		gridGap: '2rem',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		transformOrigin: 'top',
		opacity: 1,
		transform: 'scaleY(100%)',
		maxHeight: '100vh',
		transition: 'all 0.2s ease-out',
		overflow: 'auto',
	},

	headerMobile__dropdownMenu__wrapper_hide: {
		opacity: 0,
		maxHeight: 0,
		transform: 'scaleY(0%)',
		transition: 'all 0.2s ease-in',
	},

	headerMobile__products: {
		width: '100%',
		position: 'relative',
		textTransform: 'uppercase',
		animation: '$productItem 150ms ease-in 200ms',
		animationFillMode: 'forwards',

		'& $headerMobile__products__item.active': {
			background: 'linear-gradient(82.78deg, #6E2FF2 5%, #232686)',
		},
	},

	headerMobile__control: {
		width: '100%',
		position: 'relative',
	},

	headerMobile__products__dropdownIcon: {
		position: 'absolute',
		right: '0.7rem',
		top: '50%',
		transform: 'translateY(-50%) scaleY(100%)',
		transition: 'all 350ms',
	},

	headerMobile__products__dropdownIcon_open: {
		transform: 'translateY(-50%) scaleY(-100%)',
	},

	headerMobile__products__list: {
		background: '#000000CC',
		color: '#FFFFFF',
		borderRadius: '0 0 .8rem .8rem',
		margin: '1rem auto 0',
		width: '12rem',
		overflow: 'hidden',
		transformOrigin: 'top',
		opacity: 1,
		transform: 'scaleY(100%)',
		transition: 'all 0.2s ease-out',
		maxHeight: '100vh',

		'& $headerMobile__products__item': {
			animation: 'none',
			opacity: 1,
		}
	},

	headerMobile__products__list_hide: {
		opacity: 0,
		maxHeight: 0,
		transform: 'scaleY(0%)',
		transition: 'all 0.2s ease-in',
	},

	headerMobile__products__item: {
		display: 'block',
		padding: '1rem',
		animation: '$productItem 150ms ease-in 200ms',
		animationFillMode: 'forwards',
		opacity: 0,
		textTransform: 'uppercase',
	},

	'@keyframes productItem': {
		'0%': {
			opacity: 0,
			transform: 'translateY(-10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(0%)',
		}
	},
}));
