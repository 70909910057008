import makeStyles from '@material-ui/core/styles/makeStyles';
import content_bg from '../../assets/img/content-bg-min.png';
import content_bg_m from '../../assets/img/content-bg-m-min.png';
import { media } from '../../hooks/useDevice';

export default makeStyles(() => ({
	home_content: {
		position: 'relative'
	},

	home_bg_head: {
		position: 'absolute',
		top: '-14vw',
		background: `url(${content_bg}) no-repeat`,
		backgroundPosition: 'top',
		backgroundSize: '100%',
		width: '100%',
		height: '100%',

		[media.mobile]: {
			background: `url(${content_bg_m}) no-repeat`,
			backgroundPosition: 'top',
			backgroundSize: '100%',
			top: '-5rem',
		}
	},

	home_bg: {
		position: 'absolute',
		width: '100%',
		maxWidth: 1920,
		top: '-10.625rem',
		left: '50%',
		transform: 'translateX(-50%)',
	},

	home_content_blocks: {
		position: 'relative',
		zIndex: 3,
	},

	home_registerBtnMobile: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textDecoration: 'none',
		background: 'linear-gradient(82.78deg, #6E2FF2 4.86%, #232686 101.82%)',
		color: '#FFFFFF',
		border: 'none',
		width: '100%',
		height: '3.16667rem',
		borderRadius: '.5rem',
		margin: '0 0 1rem',
		fontFamily: "'Quicksand', sans-serif",
		fontSize: '1.333333rem',
		textTransform: 'uppercase',
	},

	home_hr: {
		margin: '1.25rem auto',
		display: 'grid',
	},
}));
