import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Banner from '../../components/Banner/Banner';
import Benefit from './Benefit/Benefit';
import WhatWeThink from './WhatWeThink/WhatWeThink';
import Popular from './Popular/Popular';
import ContactUs from '../../components/ContactUs/ContactUs';
import home_hr from '../../assets/img/home/home-hr-min.png';
import useStyles from './styles';
import Footer from '../../components/Footer/Footer';
import home_bg from '../../assets/img/home/home-bg-min.png';
import { useDevice } from '../../hooks/useDevice';
import ApiComponent from '../../api/ApiComponent';
import Translate from '../../components/Translate';

const Home = () => {
	const classes = useStyles();
	const device = useDevice();
	const [data, setData] = useState({});

	return (<div>
		<ApiComponent path={'/global/landing_page/home'} setData={setData} />

		<Banner images={device.desktop ? data.banner?.desktop : data.banner?.mobile} />

		<div className={classes.home_content}>
			<div className={classes.home_bg_head} />

			{device.desktop && <img className={classes.home_bg} src={home_bg} alt={''}/>}

			<div className={classes.home_content_blocks}>
				{
					device.mobile && <div className={'container'}>
						<Link to={'/register'} className={classes.home_registerBtnMobile}>
							<Translate i18nKey='Register' />
						</Link>
					</div>
				}
				<Benefit />
				<img className={classes.home_hr} src={home_hr} alt='' />
				<WhatWeThink data={data}/>
			</div>

			<Popular />

			<div className={classes.home_content_blocks}>
				{device.desktop && <img className={classes.home_hr} src={home_hr} alt=''/>}
				<ContactUs />
			</div>

		</div>
		<Footer />

	</div>);
};

export default Home;
