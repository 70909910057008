import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
	header: {
		background: '#000000CC',
		fontFamily: "'Quicksand', sans-serif",
		fontWeight: 700,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1000,
	},

	header_container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	header_logo: {
		height: '4.3229166666667vw',
		minHeight: 70,
		width: 'auto',
	},

	header_left: {
		display: 'grid',
		gridTemplateColumns: 'auto',
		gridGap: '1.28vw',
	},

	header_buttons: {
		display: 'grid',
		gridGap: 11,
		gridTemplateColumns: 'auto auto',
		justifyContent: 'flex-end',
		margin: '1.125rem 0 0',
	},

	header_registerBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textDecoration: 'none',
		background: 'linear-gradient(82.78deg, #6E2FF2 4.86%, #232686 101.82%)',
		color: '#FFFFFF',
		border: 'none',
		width: '6.875rem',
		height: '2rem',
		borderRadius: '0.625rem',
		fontFamily: 'inherit',
		textTransform: 'uppercase',
	},

	header_lang: {
		width: '6em',
	},

	header_menu: {
		display: 'grid',
		gridGap: '1.875rem',
		gridTemplateColumns: 'auto auto auto auto',
		justifyContent: 'flex-end',
		textTransform: 'uppercase',
	},

	header_menu__item: {
		position: 'relative',
		textAlign: 'center',
		padding: '0 0 1.25vw',

		'&.active:after': {
			content: '" "',
			position: 'absolute',
			top: '.6em',
			left: '-.5rem',
			width: 'calc(100% + 1rem)',
			height: 3,
			background: '#5A3BED',
			borderRadius: 2,
		}
	},

	header__popper: {
		position: 'absolute',
		color: '#FFFFFF',
		zIndex: 10000000,
		width: '100%',
		top: '100% !important',
		transform: 'none !important',

		'& .MuiPaper-root': {
			background: '#000000CC',
			borderRadius: '0px 0px 10px 10px',
			borderTop: 'none',
			overflow: 'hidden',
		},
	},

	header_menu__product: {
		width: 157,
		cursor: 'pointer',
	},

	header__popper__item: {
		height: 53,
		fontFamily: "'Quicksand', sans-serif",
		color: '#FFFFFF',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		fontSize: '1rem',

		'&:hover': {
			background: 'linear-gradient(82.78deg, #6E2FF2 4.86%, #232686 101.82%)',
		},

		'&.active $header__popper__txt:after': {
			content: '" "',
			position: 'absolute',
			top: 'calc(50% - 1px)',
			left: '-.5rem',
			width: 'calc(100% + 1rem)',
			height: 3,
			background: '#5A3BED',
			borderRadius: 2,
		}
	},

	header__popper__txt: {
		position: 'relative',
	},
}));
