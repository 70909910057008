import React, { useEffect, useState, useMemo, memo } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import toString from 'lodash/toString';
import { useSetRecoilState } from 'recoil';

import sendRequest from './sendRequest';
import socialState from '../recoil/social';
import Loading from '../components/Loading/Loading';

const ApiComponent = ({
	path = '',
	payload = {},
	setData = () => {}, // Function to update data
	deps = [], // Call API when deps change
	successCallback = () => {}, // Callback when success
	loaderEffect = true,
}) => {
	const [apiData, setApiData] = useState({});
	const setSocial = useSetRecoilState(socialState);
	const [loading, setLoading] = useState(false);

	const metaData = useMemo(() => {
		const meta = get(apiData, 'web_page.meta_data');

		if (meta) {
			return meta;
		}
	}, [apiData]);

	useEffect(() => {
		const customer_service = get(apiData, 'customer_service');

		if (customer_service) {
			setSocial(customer_service);
		}
	}, [apiData, setSocial]);

	useEffect(() => {
		setLoading(() => true);

		sendRequest(path, payload).then(response => {
			setLoading(() => false);

			if (get(response, 'data.success', false)) {
				if (isFunction(setData)) {
					const data = get(response, 'data.data', {});

					setData(data);
					setApiData(() => data);
				}
				if (isFunction(successCallback)) {
					successCallback();
				}
			} else {
				console.log('Error:', response);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);

	return (
		<>
			{loaderEffect && loading && <Loading/>}
			<Helmet>
				{parse(toString(metaData))}
			</Helmet>
		</>
	);
};

export default memo(ApiComponent);
