import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import clsx from 'clsx';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import england_flag from '../../assets/img/england-flag.jpg';
import indonesia_flag from '../../assets/img/indonesia-flag.jpg';
import dropdown from '../../assets/img/dropdown.svg';

import { useStyles } from './styles';

export const listLanguage = [
	{
		id: 1,
		language: 'English',
		flag: england_flag,
		code: 'en',
	},
	{
		id: 2,
		language: 'Indonesian',
		flag: indonesia_flag,
		code: 'id',
	},
];

const SelectLanguage = ({ className = '' }) => {
	const [openDropdownLanguage, setOpenDropdownLanguage] = useState(false);
	const [currentLanguage, setCurrentLanguage] = useState(null);
	const refDropdownLanguage = useRef();
	const { i18n } = useTranslation();

	const onSelectLanguage = useCallback((language) => {
		setCurrentLanguage(language);
		setOpenDropdownLanguage(false);
		i18n.changeLanguage(language.code);
		// localStorage.setItem('i18nextLng', language.code);
	}, [i18n]);

	const classes = useStyles();

	useOnClickOutside(refDropdownLanguage, () => setOpenDropdownLanguage(false));

	useEffect(() => {
		if (i18n.language) {
			const language = listLanguage.find((lang) => lang.code === i18n.language);
			if (language) {
				setCurrentLanguage(language);
			}
		}
	}, [i18n.language]);

	return (
		<div ref={refDropdownLanguage} className={clsx(classes.selectLanguage__container, className)}>

			{/* --- Select UI --- */}
			<div
				className={clsx(
					classes.selectLanguage__dropDown,
					{ [classes.selectLanguage__dropDown_active]: openDropdownLanguage }
				)}
				onClick={() => setOpenDropdownLanguage(val => !val)}
			>
				<div className={classes.selectLanguage__dropDown__left}>
					<img
						alt=''
						src={get(currentLanguage, 'flag')}
						className={classes.selectLanguage__globeIcon}
					/>
					<span className={classes.selectLanguage__language}>{get(currentLanguage, 'code')}</span>
				</div>
				<img src={dropdown} alt={''} className={clsx(classes.selectLanguage__dropDownIcon, {
					[classes.selectLanguage__dropDownIcon_open]: openDropdownLanguage
				})} />
			</div>
			{/* --- Select UI - END --- */}

			{/* --- List dropdown --- */}
			<ul className={clsx(classes.selectLanguage__dropdown__list,
				{ [classes.selectLanguage__dropdown__list_hide]: !openDropdownLanguage }
			)}>
				{listLanguage.map((item) => (
					<li
						key={item.id}
						className={classes.selectLanguage__dropdown__item}
						onClick={() => onSelectLanguage(item)}
					>
						<img
							alt=''
							src={item.flag}
							className={classes.selectLanguage__globeIcon}
						/>
						<span className={classes.selectLanguage__language}>{item.code}</span>
					</li>
				))}
			</ul>
			{/* --- List dropdown - END --- */}
		</div>
	);
};

export default SelectLanguage;
